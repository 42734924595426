import React from 'react'
import { styled } from '@mui/material/styles'

import { Box, Typography } from '@mui/material'

const PREFIX = 'StyledList'

const classes = {
    list: `${PREFIX}-list`,
    listItem: `${PREFIX}-listItem`,
    listItemText: `${PREFIX}-listItemText`,
}

const Root = styled('div')(({ theme: { palette, spacing } }) => ({
    [`& .${classes.list}`]: {
        marginBottom: spacing(1),
    },

    [`&.${classes.listItem}`]: {
        marginLeft: spacing(3),
        marginBottom: spacing(1),
        fontSize: 18,
    },
}))

function StyledList(props) {
    const { type, children } = props
    const listBullet = index => {
        if (type === 'ul') return '•'
        return `${index + 1}.`
    }

    return (
        <Root className={classes.listItem}>
            {children.map((child, index) => {
                return child === '\n' ? (
                    <Box key={index} sx={{ height: 10 }} />
                ) : (
                    <div key={index} className={classes.listItem}>
                        <Typography variant="body1" component="span">
                            {listBullet(Math.floor(index / 2))}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ ml: 1.5, fontSize: 18 }}
                        >
                            {child.props.children}
                        </Typography>
                    </div>
                )
            })}
        </Root>
    )
}

export default StyledList
