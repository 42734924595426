import React from 'react'

import { styled } from '@mui/system'

const StyledComponent = styled('blockquote')(({ theme: { palette } }) => ({
    borderColor: palette.divider,
    borderLeft: '1 solid',
}))

export default function StyledBlockQuote({ children }) {
    return <StyledComponent>{children}</StyledComponent>
}
