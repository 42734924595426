import React from 'react';

import { Link } from 'gatsby-theme-material-ui';

import kebabCase from '../../helper-functions/kebabCase';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

//https://css-tricks.com/hash-tag-links-padding/
//https://stackoverflow.com/questions/59178802/how-do-you-change-a-style-of-a-child-when-hovering-over-a-parent-using-mui-style

function StyledH2(props) {
    const { children } = props;
    const theme = useTheme();
    const hashLink = kebabCase(children[0]);

    const adjustedHeight =
        theme.mixins.toolbar.minHeight + parseInt(theme.spacing(2));

    const adjustedMarginTop = adjustedHeight / parseInt(theme.spacing(1));

    return (
        <Typography
            id={hashLink}
            variant="h6"
            component="h2"
            sx={{
                my: 2,
                '&:before': {
                    display: 'block',
                    content: '""',
                    marginTop: -adjustedMarginTop,
                    height: adjustedHeight,
                    visibility: 'hidden',
                },
                '&:hover': {
                    '& .icon': {
                        visibility: 'visible',
                    },
                },
            }}
        >
            {children}
            <Box
                component="span"
                sx={{ visibility: 'hidden' }}
                className="icon"
            >
                <Link to={`#${hashLink}`}>
                    <svg
                        aria-hidden="true"
                        height="20"
                        version="1.1"
                        viewBox="0 0 16 16"
                        width="20"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                        ></path>
                    </svg>
                </Link>
            </Box>
        </Typography>
    );
}

export default StyledH2;
