import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';

import { faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Footer from '../components/Footer';
import Layout from '../components/Layout';
import RelatedArticles from '../components/RelatedArticles';
import Seo from '../components/Seo';
import SocialShare from '../components/SocialShare';
import TableOfContents from '../components/TableOfContents';
import blogComponentMapping from '../helper-functions/blogComponentMapping';

import {
    Alert,
    AlertTitle,
    Box,
    Container,
    Divider,
    Fab,
    SwipeableDrawer,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

const BlogPostTemplate = props => {
    const { data, location } = props;
    const post = data.markdownRemark;

    const featuredImage = getImage(post.frontmatter.featured_image);
    const imageSrc = getSrc(post.frontmatter.featured_image);
    const { nodes: posts } = data.allMarkdownRemark;

    const { description, image_alt_text, title } = post.frontmatter;

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const adjustedHeight = theme.mixins.toolbar.minHeight;

    const [drawerState, setDrawerState] = useState(false);

    return (
        <Layout sx={{ bgcolor: 'grey.100' }}>
            <Container maxWidth="lg" sx={{ pt: 2, position: 'relative' }}>
                <Alert
                    severity="info"
                    icon={<FontAwesomeIcon icon={faNoteSticky} />}
                >
                    <AlertTitle>
                        Check out our FREE PM fundamentals certification course{' '}
                        <Link to="/pm-course/">here</Link>
                    </AlertTitle>
                </Alert>
                <Typography
                    variant="h2"
                    component="h1"
                    gutterBottom
                    align="center"
                    color="primary"
                    margin="auto"
                    my={6}
                >
                    {title}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                    }}
                >
                    {!!post.headings.length && !isSmall && (
                        <Box
                            mr={2}
                            position="sticky"
                            top={`${adjustedHeight + 16}px`}
                            flex="0 0 350px"
                            overflowY="auto"
                            p={2}
                            borderRadius={2}
                        >
                            <Typography variant="body2" gutterBottom>
                                · {post.timeToRead} min read ·
                            </Typography>
                            <SocialShare
                                title={title}
                                url={location.href}
                                description={description}
                                source={location.hostname}
                            />
                            <TableOfContents headings={post.headings} />
                        </Box>
                    )}

                    <Container
                        maxWidth="md"
                        fixed
                        sx={{
                            borderColor: {
                                md: 'grey.300',
                            },
                            borderLeft: {
                                md: '1px solid',
                            },
                        }}
                    >
                        {isSmall && (
                            <SwipeableDrawer
                                anchor="top"
                                open={drawerState}
                                onClose={() => setDrawerState(false)}
                                onOpen={() => setDrawerState(true)}
                            >
                                <Box p={2}>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        {title}
                                    </Typography>
                                    <TableOfContents
                                        headings={post.headings}
                                        onNavigateToHeading={() =>
                                            setDrawerState(false)
                                        }
                                    />
                                </Box>
                            </SwipeableDrawer>
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <GatsbyImage
                                image={featuredImage}
                                alt={(image_alt_text, title)}
                                loading="eager"
                            />
                        </Box>

                        <Box p={{ xs: 1, md: 4 }}>
                            {isSmall && (
                                <Box>
                                    <Typography variant="body2" gutterBottom>
                                        · {post.timeToRead} min read ·
                                    </Typography>
                                    <SocialShare
                                        title={title}
                                        url={location.href}
                                        description={description}
                                        source={location.hostname}
                                    />
                                </Box>
                            )}
                            {blogComponentMapping(post.htmlAst)}
                        </Box>
                    </Container>
                </Box>
            </Container>

            <Divider sx={{ my: 4 }} />
            <Container maxWidth="md">
                <Typography variant="h5">You may also like</Typography>
                <RelatedArticles posts={posts} />
            </Container>
            {isSmall && (
                <Fab
                    sx={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                    }}
                    onClick={() => setDrawerState(true)}
                    size="medium"
                    color="primary"
                >
                    <FontAwesomeIcon icon={faList} />
                </Fab>
            )}

            <Footer />
        </Layout>
    );
};

BlogPostTemplate.propTypes = {
    data: PropTypes.Object,
    location: PropTypes.Object,
};

export const Head = props => {
    const { data, location } = props;
    const post = data.markdownRemark;

    const imageSrc = getSrc(post.frontmatter.featured_image);

    const { description, title } = post.frontmatter;

    return (
        <Seo
            title={title}
            description={description}
            imageSrc={imageSrc}
            url={location.href}
        />
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!, $tags: [String!]) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            htmlAst
            timeToRead
            fields {
                slug
            }
            headings(depth: h2) {
                value
            }
            frontmatter {
                title
                image_alt_text
                description
                featured_image {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            layout: CONSTRAINED
                            height: 400
                        )
                        id
                    }
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { tags: { in: $tags } }, id: { ne: $id } }
            limit: 3
            sort: { order: ASC, fields: frontmatter___date }
        ) {
            nodes {
                id
                excerpt(pruneLength: 125)
                fields {
                    slug
                }
                frontmatter {
                    title
                    image_alt_text
                    featured_image {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                layout: CONSTRAINED
                                height: 100
                            )
                            id
                        }
                    }
                }
            }
        }
    }
`;
