import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const PREFIX = 'StyledH3'

const classes = {
    root: `${PREFIX}-root`,
}

const StyledTypography = styled(Typography)(
    ({ theme: { palette, spacing, typography } }) => ({
        [`&.${classes.root}`]: {
            fontWeight: typography.fontWeightMedium,
            margin: spacing(1, 0),
        },
    })
)

function StyledH3(props) {
    const { children } = props
    return (
        <StyledTypography
            className={classes.root}
            variant="subtitle1"
            component="h3"
            gutterBottom
        >
            {children}
        </StyledTypography>
    )
}

StyledH3.propTypes = {}
export default StyledH3
