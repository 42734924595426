import React from 'react';

import { Link } from 'gatsby-theme-material-ui';

import kebabCase from '../helper-functions/kebabCase';

import { Box, Divider, Typography } from '@mui/material';

function TableOfContents(props) {
    const { headings, onNavigateToHeading } = props;

    return (
        <Box
            sx={{
                my: 1,
                height: '80vh',
                overflowY: 'scroll',
            }}
        >
            {headings.map(({ value }, index) => {
                const hash = kebabCase(value);
                return (
                    <div key={index}>
                        <Typography
                            component={Link}
                            to={`#${hash}`}
                            onClick={onNavigateToHeading || undefined}
                            underline="none"
                            variant="subtitle1"
                            sx={{ my: 2 }}
                        >
                            {value}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                    </div>
                );
            })}
        </Box>
    );
}

export default TableOfContents;
