import React from 'react'
import rehypeReact from 'rehype-react'

import StyledBody from '../components/blog-styled-components/StyledBody'
import StyledH2 from '../components/blog-styled-components/StyledH2'
import StyledH3 from '../components/blog-styled-components/StyledH3'
import StyledList from '../components/blog-styled-components/StyledList'
import StyledBlockQuote from '../components/blog-styled-components/StyledBlockQuote'
//https://using-remark.gatsbyjs.org/custom-components/

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        h2: StyledH2,
        h3: StyledH3,
        p: StyledBody,
        ul: props => StyledList({ type: 'ul', ...props }),
        ol: props => StyledList({ type: 'ol', ...props }),
        blockquote: StyledBlockQuote,
    },
}).Compiler

export default renderAst
