import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const PREFIX = 'StyledBody'

const classes = {
    root: `${PREFIX}-root`,
}

const StyledTypography = styled(Typography)(
    ({ theme: { palette, spacing, typography } }) => ({
        [`&.${classes.root}`]: {
            lineHeight: 1.75,
            marginBottom: spacing(1),
            fontSize: 18,
        },
    })
)

function StyledBody(props) {
    const { children } = props
    return (
        <StyledTypography className={classes.root} variant="body1" gutterBottom>
            {children}
        </StyledTypography>
    )
}

StyledBody.propTypes = {}
export default StyledBody
