import React from 'react';

import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';

import { Box, ButtonBase, Typography } from '@mui/material';

function BlogCardTall(props) {
    const { post } = props;

    const title = post.frontmatter.title || post.fields.slug;

    const featuredImage = getImage(post.frontmatter.featured_image);

    return (
        <ButtonBase
            key={post.id}
            component={Link}
            to={`/blog${post.fields.slug}`}
            underline="none"
            sx={[
                {
                    p: 2,
                    borderRadius: 4,
                    boxShadow: 3,
                    width: 1,
                    height: 1,
                    display: 'block',
                },
                theme => ({
                    '&:hover': {
                        backgroundColor: theme.palette.grey[50],
                        boxShadow: 10,
                    },
                }),
            ]}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <GatsbyImage
                    image={featuredImage}
                    alt={post.frontmatter.image_alt_text}
                />
            </Box>
            <Box sx={{ height: 100, display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
            </Box>

            <Typography variant="body1" component="div" color="textSecondary">
                {post.excerpt}
                <Typography color="primary" display="inline">
                    Continue Reading
                </Typography>
            </Typography>
        </ButtonBase>
    );
}

BlogCardTall.propTypes = {
    post: PropTypes.object,
};
export default BlogCardTall;
