import React from 'react'

import BlogCardTall from './BlogCardTall'

import { Grid } from '@mui/material'

function RelatedArticles(props) {
    const { posts } = props

    return (
        <Grid
            container
            spacing={3}
            sx={{ mt: 2 }}
            alignItems="stretch"
            justifyContent="space-around"
        >
            {posts.map(post => (
                <Grid item xs={12} md={4} key={post.id}>
                    <BlogCardTall post={post} />
                </Grid>
            ))}
        </Grid>
    )
}

export default RelatedArticles
