import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import {
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share';

import { styled } from '@mui/material/styles';

const PREFIX = 'SocialShare';

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme: { palette, spacing } }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 150,
        margin: spacing(1, 0),
    },
}));

function SocialShare(props) {
    const { url, title, description, source } = props;
    return (
        <Root className={classes.root}>
            <TwitterShareButton url={url} title={title}>
                <TwitterIcon size={25} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton
                url={url}
                title={title}
                summary={description}
                source={source}
            >
                <LinkedinIcon size={25} round={true} />
            </LinkedinShareButton>

            <RedditShareButton url={url} title={title}>
                <RedditIcon size={25} round={true} />
            </RedditShareButton>

            <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={25} round={true} />
            </WhatsappShareButton>

            <FacebookShareButton url={url} quote={description}>
                <FacebookIcon size={25} round={true} />
            </FacebookShareButton>
        </Root>
    );
}

SocialShare.propTypes = {};
export default SocialShare;
